import React, {useEffect, useState} from 'react';
import {months, years} from "../../../../utills/constants/general";
import DatePicker from "react-datepicker";
import {eventGetAllByDate} from "../../../../services/EventService";
import "./DashboardEventsFilter.scss"
import DashboardEventsFilterItem from "./DashboardEventsFilterItem";
import Button from "../../../../components/Button/Button";
import {useSelector} from "react-redux";
import moment from "moment";
import {getDaysInMonth} from "../../../../utills/helpers/string";
import determineCanUseActiveElement from "react-tabs/lib/components/UncontrolledTabs";

const DashboardEventsFilter = ({accountId}) => {
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [events, setEvents] = useState([]);
    const [monthEvents, setMonthEvents] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);
    const [eventIndex, setEventIndex] = useState(0);

    const {isAdmin} = useSelector(state => state.dashboardState.user);

    useEffect(() => {
        eventGetAllByDate(new Date(), new Date(), accountId).then(response => {
            if (response.data.success === true) {
                setEvents(response.data.data.items);
            }
        }).finally(() => setEventsLoading(false)).catch(err => {
            setEvents([])
        })
    }, [])

    useEffect(() => {
        setEventsLoading(true)
        eventGetAllByDate(new Date(), new Date(), accountId).then(response => {
            if (response.data.success === true) {
                setEvents(response.data.data.items);
            }
        }).finally(() => setEventsLoading(false)).catch(err => {
            setEvents([])
        })
    }, [accountId])

    useEffect(() => {
        eventGetAllByDate(new Date(year, month, 1), new Date(year, month + 1, 0), accountId).then(response => {
            if (response.data.success === true) {
                const days = getDaysInMonth(month, year).map(day => {
                    const today = response.data.data.items.filter(el => el.publishingDates.includes(moment(day).format('YYYY-MM-DD[T]00:00:00')))
                    if (today.length === 0) {
                        return 'dashboard-datepicker-day';
                    }
                    const hasInReview = today.filter(el => el.approvalStatus === 0);
                    const approvedCount = today.filter(el => el.approvalStatus === 1);

                    let color;
                    if (approvedCount.length === today.length) {
                        color = 'text-success'
                    } else if (hasInReview.length !== 0) {
                        color = 'text-orange'
                    } else {
                        color = 'text-danger'
                    }
                    return `dashboard-datepicker-day ${color}`
                })
                setMonthEvents(days)
            }
        })
    }, [month, year, accountId])

    const handleChange = (date) => {
        if (date.getMonth() !== month) {
            setMonth(date.getMonth())
        }
        setSelectedDay(date);
        setEventsLoading(true);
        eventGetAllByDate(date, date, accountId).then(response => {
            if (response.data.success === true) {
                setEventIndex(0);
                setEvents(response.data.data.items);
            }
        }).finally(() => setEventsLoading(false)).catch(err => {
            setEvents([])
        })
    }

    return (
        <div className={"dashboard-events-filter"}>
            <div className="dashboard-events-filter-date">
                <DatePicker
                    inline
                    selected={selectedDay}
                    onChange={(date) => handleChange(date)}
                    calendarClassName="dashboard-datepicker"
                    dayClassName={(date) => {
                        if (date.getMonth() !== selectedDay.getMonth()) {
                            return 'dashboard-datepicker-day';
                        }
                        return monthEvents[date.getDate()-1];
                    }}
                    shouldCloseOnSelect={false}
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth
                    }) => (
                        <div className={"dashboard-datepicker-header"}>
                            <select
                                value={months[date.getMonth()]}
                                onChange={({target: {value}}) => {
                                    changeMonth(months.indexOf(value))
                                    setSelectedDay(new Date(year, months.indexOf(value), 1))
                                    setMonth(months.indexOf(value))
                                    handleChange(new Date(year, months.indexOf(value), 1))
                                }}>
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={date.getFullYear()}
                                onChange={({target: {value}}) => {
                                    changeYear(value)
                                    setSelectedDay(new Date(value, month, 1))
                                    setYear(value)
                                    handleChange(new Date(value, month, 1))
                                }}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                />
                <div className="dashboard-events-filter-date-controls">
                    <Button className={`${eventIndex === 0 ? 'disabled' : ''}`} variant='secondary' onClick={() => {
                        if (eventIndex === 0) return
                        setEventIndex(eventIndex - 1)
                    }}>
                        Prev Event
                    </Button>
                    <Button className={`${eventIndex === events.length - 1 || events.length === 0 ? 'disabled' : ''}`}
                            variant='secondary' onClick={() => {
                        if (eventIndex === events.length - 1) return
                        setEventIndex(eventIndex + 1)
                    }}>
                        Next Event
                    </Button>
                </div>
            </div>

            <div className={`dashboard-events-filter-list ${eventsLoading || events.length === 0 ? 'centered' : ''}`}>
                {eventsLoading ? (
                        <div className="spinner-grow text-dark" role="status" style={{width: "5em", height: "5em"}}>

                        </div>
                    ) :
                    events.length === 0 ? 'No events for this day' : (
                        <>
                            {isAdmin ? (
                                <strong className={"dashboard-events-filter-list-number"}>{eventIndex + 1} event
                                    of {events.length}</strong>
                            ) : null}
                            <DashboardEventsFilterItem event={events[eventIndex]}/>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default DashboardEventsFilter;